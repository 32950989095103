<template>
  <!-- BEGIN: HTML Table Data -->
  <div>
    <div class="overflow-x-auto scrollbar-hidden mt-2">
      <div>{{ title }}</div>
      <grid
        ref="grid"
        v-bind="gridOptions"
        @addNewRow="
          (row, callback) => {
            initData(row);
            callback();
          }
        "
        @edit="onGridEdit"
        @modalResize="onGridModalResize"
        @removeSelectedRows="onGridRemoveSelectedRows"
      >
        <template #toolbar>
          <div class="flex py-2">
            <vxe-button
              status="success"
              v-show="permission.isCreate"
              icon="vxe-icon--plus"
              @click="grid.addNewRow"
              >新增帳號</vxe-button
            >
          </div>
        </template>

        <template #password="{ row }">
          <slot name="password" :row="row">
            <div class="flex" v-if="!isEditing">
              <button
                class="button rounded-full mr-1 mb-2 bg-theme-9 text-white"
                title="變更密碼"
                v-show="permission.isEditing"
                @click="changePassword(row)"
              >
                <FontAwesome icon="key" class="w-4 h-4" />
              </button>
            </div>
          </slot>
        </template>

        <template #modal="{ row, submit, reset }">
          <vxe-form
            :title-width="100"
            title-align="right"
            v-bind="formOptions"
            :data="row"
            @reset="reset"
            @submit="submit"
          >
          </vxe-form>
        </template>
        <template #readonly="{ row }">
          <vxe-switch
            v-model="row.Enabled"
            open-label="可下單"
            close-label="限瀏覽"
            :disabled="!permission.isEditing"
            @change="switchChange(row, $event)"
          ></vxe-switch>
        </template>
      </grid>
      <ChangePassword
        :data="store"
        :show="changePasswordVisible"
        @save="onChange"
        @update:show="changePasswordVisible = $event"
      ></ChangePassword>
    </div>
  </div>
  <!-- END: HTML Table Data -->
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}
.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script>
import { computed, onMounted, watch } from "vue";
import CloudFun, { defineComponent, ref, reactive } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import ChangePassword from "./ChangePassword.vue";
export default defineComponent({
  components: {
    Grid,
    ChangePassword,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const changePasswordVisible = ref(false);
    const grid = ref({});
    const store = ref({});
    const permission = reactive({
      isCreate: model?.user.Permissions.includes("StoreCreate"),
      isEditing: model?.user.Permissions.includes("StoreUpdate"),
      isDelete: model?.user.Permissions.includes("StoreDelete"),
    });
    watch(
      () => props.id,
      () => {
        store.value = props.data;
        console.log("store.value", props.id);
        grid.value.reload();
      }
    );

    const gridOptions = {
      title: "經銷商帳號",
      canCreateRow: permission.isCreate,
      canUpdateRow: permission.isEditing,
      canDeleteRow: permission.isDelete,
      multiselect: false,
      toolbarConfig: {
        custom: false,
        refresh: false,
      },

      columns: [
        {
          field: "Account",
          title: "帳號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "200",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Name",
          title: "帳號姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "200",
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },

        {
          field: "LoginTime",
          title: "登入時間",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "300",
          //   editRender: {
          //     name: "$input",
          //     immediate: true,
          //     attrs: { type: "text" },
          //   },
        },
        {
          field: "Enabled",
          title: "權限",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          width: "150",
          slots: { default: "readonly" },
        },
        {
          field: "Password",
          title: "密碼",
          width: "80",
          slots: { default: "password" },
        },
      ],
      promises: {
        query: model
          ? (params) =>
              model.dispatch("store/queryByOrganization", {
                key: props.id,
                ...params,
              })
          : undefined,
        queryAll: model
          ? () => model.dispatch("store/queryByOrganization", props.id)
          : undefined,
        save: model
          ? (params) => {
              if (permission.isEditing || permission.isCreate) {
                if (params.insertRows.length > 0) {
                  params.insertRows[0].Id =
                    "{00000000-0000-0000-0000-000000000000}";
                }

                return model.dispatch("store/save", params);
              } else return Promise.resolve();
            }
          : undefined,
      },
      modalConfig: { height: "Auto", width: "500" },
    };
    const formOptions = {
      items: [
        {
          field: "Name",
          title: "姓名",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Account",
          title: "經銷商帳號",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              placeholder: "請輸入文字",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Password",
          title: "密碼",
          span: 24,
          itemRender: {
            name: "$input",
            props: {
              type: "password",
              placeholder: "請輸入密碼",
              disabled: !permission.isEditing,
            },
          },
        },
        {
          field: "Enabled",
          title: "權限",
          span: 24,
          itemRender: {
            name: "$switch",
            props: {
              openLabel: "可下單",
              closeLabel: "限瀏覽",
              disabled: !permission.isEditing,
            },
          },
        },
        // {
        //   field: "Password",
        //   title: "密碼",
        //   span: 24,
        //   itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        // },

        // {
        //   field: "Email",
        //   title: "Email",
        //   span: 24,
        //   itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        // },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Name: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入帳號名稱",
          },
        ],
        Account: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入帳號",
          },
        ],
        Password: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入密碼",
          },
        ],
        // Price: [{ type: "number", required: true, message: "售價" }],
        // MemberPrice: [{ type: "number", required: true, message: "會員價" }],
        // Published: [{ required: true }],
      },
    };

    const initData = (row) => {
      row.Id = null; // "{00000000-0000-0000-0000-000000000000}";
      row.Name = "";
      row.Account = "";
      row.Password = "";
      row.Enabled = true;
      row.OrganizationId = props.id;
    };

    onMounted(() => {
      console.log("");
    });

    const switchChange = async (row, $event) => {
      const payload = await model?.dispatch("store/find", row.Id);
      if (payload) {
        payload.Enabled = $event.value;
        await model?.dispatch("store/update", payload);
      }
    };

    const changePassword = (row) => {
      console.log("row", row);
      store.value = row;
      changePasswordVisible.value = true;
    };

    const onChange = (row, callback) => {
      console.log("row", row);
      model.dispatch("store/changePassword", row).then(
        (resp) => {
          console.log("resp", resp);
          CloudFun.send("info", {
            subject: "成功",
            content: "密碼變更成功",
          });
        },
        (failure) =>
          CloudFun.send("error", { subject: "操作失敗！", content: failure })
      );
      if (callback) callback();
    };

    return {
      initData,
      onChange,
      switchChange,
      changePasswordVisible,
      changePassword,
      grid,
      gridOptions,
      formOptions,
      store,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      permission,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row, callback) {
      const column = this.formOptions.items.find((e) => e.field === "Password");
      column.visible = row.Id === null;
      const entity = row.Id
        ? await this.$model.dispatch("store/find", row.Id)
        : undefined;
      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
  },
});
</script>
